.svg-inc__active {
  svg {
    path {
      stroke: white;
    }

    &:hover {
      path {
        stroke: #A08DFF;
        transition: all .3s ease;
      }
    }
  }
}